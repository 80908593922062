import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './EditReceipt.scss';
import { userActions } from '../../_store';
import { creditcardActions } from '../../_store';
import { clientsActions } from '../../_store';
import { payeeActions } from '../../_store';
import { projectsActions } from '../../_store';
import { tasksActions } from '../../_store';
import { subtasksActions } from '../../_store';
import { departmentActions } from '../../_store';
import { purchaseTypeActions } from '../../_store';
import { dashboardActions } from "../../_store";
import Select from 'react-select';
import { Navigate, useNavigate } from 'react-router-dom';
import ImageUploading from 'react-images-uploading';
import { history } from '../../_helpers';
import CurrencyInput from 'react-currency-input-field';
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";

const billTo = [
  { value: 'C', label: 'Client', key: 1 },
  { value: 'T', label: 'Tek', key: 2 },
  { value: 'P', label: 'Personal', key: 3 }
]

export { EditReceipt };

function EditReceipt(props) {
  const [isClearable, setIsClearable] = useState(true);
  const [billOption, selectBillTo] = useState();
  const [clientOption, setClient] = useState();
  const [creditOption, setCreditOption] = useState("false");
  const [clientExpTaskLevel, setClientExpTaskLevel] = useState();
  const [subTaskId, setSubTaskId] = useState();
  const [projectOption, setProject] = useState();
  const [submitCheck, setSubmit] = useState(false);
  const [taskOption, setTask] = useState();
  const [saveError, setError] = useState('');
  const [images, setImages] = React.useState([]);
  // const [imagesMobile, setMobileImages] = useState(null);
  let [loading, setLoading] = useState(false);
  const { user: authUser } = useSelector(x => x.auth);
  const [response, setResponse] = useState(false);
  const [projectClear, setProjectClear] = useState(true);
  const [taskClear, setTaskClear] = useState(true);
  const [clientClear, setClientClear] = useState(true);
  

  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }
  let userDefaultValue = {
    user_active: authUser.user.user_active,
    user_fname: authUser.user.user_fname,
    user_id: authUser.user.user_id,
    user_lname: authUser.user.user_lname,
    user_mname: authUser.user.user_mname
  }

  const { payee } = useSelector(x => x.payee);
  const [userValue, setUserValue] = useState(userDefaultValue.user_id);
  const current = new Date();
  const Todaysdate = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current.getDate().toString().padStart(2, '0')}`;
  // const Todaysdate = `${current.getFullYear()}-${(current.getMonth() >= 9 ? (current.getMonth() + 1) : '0' + (current.getMonth() + 1))}-${current.getDate() >= 9 ? current.getDate() : '0' + current.getDate()}`;

  const [ticketNumber, setTicketNumber] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector(x => x.users);

  const { clients } = useSelector(x => x.clients);
  let { projects } = useSelector(x => x.projects);
  let { tasks } = useSelector(x => x.tasks);
  let { subtasks } = useSelector(x => x.subtasks);
 
  const { creditcards } = useSelector(x => x.creditcards);
  let { purchaseType } = useSelector(x => x.purchaseType);
  let creditDefaultOption = { cc_active: '', cc_id: '', cc_name_on_card: '', cc_nbr: 'Select...', cc_user_id: '' }

  let bindObj;
  var transDate;
  bindObj = props.formProps.modaldata;
 
  const targetDiv = document.getElementById("image-upload")?.getElementsByTagName("input")[0];
  if (bindObj.file_path == '') {
    targetDiv?.setAttribute('required', 'required');
  }
  if (images.length > 0) {
    targetDiv?.removeAttribute('required');
  }

  transDate = moment(bindObj.cc_trans_dt).format('YYYY-MM-DD')
  const [currentDate, setDate] = useState(transDate);
  const [ccIdValue, setCCIdValue] = useState(creditDefaultOption.cc_id);


  useEffect(() => {
    if (bindObj.cc_trans_bill_to == 'C') {
      selectBillTo(1)
    } else if (bindObj.cc_trans_bill_to == 'T') {
      selectBillTo(2)
    } else if (bindObj.cc_trans_bill_to == 'P') {
      selectBillTo(3)
    }
    if (parseFloat(bindObj.cc_trans_amt, 10) < 0) {
      setCreditOption("true");
    }
    dispatch(userActions.getAll());
    dispatch(creditcardActions.getAll());
    dispatch(clientsActions.getAll());
    dispatch(payeeActions.getAll());
    dispatch(departmentActions.getAll());
    dispatch(purchaseTypeActions.getAll());
  }, []);


  const maxNumber = 5;

  //image upload

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  //Bill dropdown
  const selectBill = (e) => {
    if (e) {
      selectBillTo(e.key);
      onChangeGetValue({ target: { value: e?.value, name: 'cc_trans_bill_to' } })
      if (e.key == 1) {
        inputField.cc_trans_purchase_type = '';
        inputField.personal = '';
      }
      else if (e.key == 2) {
        inputField.cc_trans_bill_to_client_id = '';
        inputField.cc_trans_bill_to_user_id = '';
      }
      else if (e.key == 3) {
        inputField.cc_trans_bill_to_client_id = '';
        inputField.cc_trans_purchase_type = '';
      }
    } else {
      selectBillTo(undefined)
    }
  };


  const selectClient = (e) => {
    if (e) {
      setClientExpTaskLevel(e.client_exp_at_task_level);
      setClientClear(true)
      const param = e.client_id;
      setClient(e.client_id);
      onChangeGetValue({ target: { value: e?.client_id, name: 'cc_trans_bill_to_client_id' } })
      inputField.cc_trans_purchase_type_id = '';
      inputField.cc_trans_bill_to_user_id = '';
      if (e.client_exp_at_task_level != null) {
        dispatch(projectsActions.getAllById({ param }));
      } else {
        inputField.cc_trans_bill_to_project_id = '';
        inputField.cc_trans_bill_to_ts_task_id = '';
        projects = []
      }
      if (billOption == 1) {
        inputField.cc_trans_purchase_type_id = '';
        inputField.cc_trans_bill_to_user_id = '';
      }
    } else {
      setClientClear(false)
      projects = [];
      tasks = [];
      setClient(undefined)
      onChangeGetValue({ target: { value: e?.client_id, name: 'cc_trans_bill_to_client_id' } })
      inputField.cc_trans_bill_to_project_id = '';
      inputField.cc_trans_bill_to_ts_task_id = '';
    }
  };


  const selectProject = (e) => {
    if (e) {
      setProjectClear(true);
      const param = e.project_id;
      setProject(e.project_id)
      dispatch(tasksActions.getAllById({ param }));
      onChangeGetValue({ target: { value: e?.project_id, name: 'cc_trans_bill_to_project_id' } })
    } else {
      tasks = [];
      setProject(undefined)
      setProjectClear(false);
    }
  };


  const selectTasks = (e) => {
    if (e) {
      setTaskClear(true)
      const param = e.ts_task_id;
      setTask(e.ts_task_id)
      dispatch(subtasksActions.getAllById({ param }));
    } else {
      setTaskClear(false)
      subtasks = [];
      setTask(undefined)
    }
  };


  const selectSubTasks = (e) => {
    if (e) {
      setSubTaskId(e.ts_task_id);
    }
  };

  const selectPersonal = (e) => {
    if (e) {
      if (billOption == 3) {
        onChangeGetValue({ target: { value: e?.user_id, name: 'cc_trans_bill_to_user_id' } })
      }
    }
  };


  //filled values
  const onChangeGetValue = e => {
    const { name, value } = e.target;
    setInputField(prevState => ({
      ...prevState,
      [name]: value,
    }));
    if (name == 'cc_trans_dt') {
      setDate(value);
    }
    if (name == 'used_by') {
      setUserValue(value);
    }
    if (name == 'cc_nbr') {
      setCCIdValue(value)
    }
    if (name == 'cc_trans_amt') {
      inputField.cc_trans_bill_amt = value
    }
  };


  const onChangeticketNumber = (e) => {
    const value = e.target.value?.replace(/\D/g, "");
    setTicketNumber(value);
    inputField.cc_trans_ticket_number = value;
  };


  const selectPurchaseType = (e) => {
    if (e) {
      if (billOption == 2) {
        onChangeGetValue({ target: { value: e?.cc_trans_purchase_type_id, name: 'cc_trans_purchase_type_id' } })
      }
    }
  };
 

  function showProject() {
    if (bindObj.cc_trans_bill_to_client_id && clients.length > 0) {
      let getClientObj = clients.find(obj => obj.client_id == bindObj.cc_trans_bill_to_client_id)
      const param = getClientObj?.client_id;
      if (getClientObj.client_exp_at_task_level != null) {
        setClientExpTaskLevel(getClientObj.client_exp_at_task_level)
        dispatch(projectsActions.getAllById({ param }));
      }
    }
  }


  useEffect(() => {
    showProject();
  }, [clients])


  function showTask() {
    if (bindObj.cc_trans_bill_to_project_id && projects.length > 0) {
      let getProjectObj = projects.find(obj => obj.project_id == bindObj.cc_trans_bill_to_project_id)
      const param = getProjectObj?.project_id;
      if (param !== undefined) {
        dispatch(tasksActions.getAllById({ param }));
      }
    }
  }


  useEffect(() => {
    showTask();
  }, [projects])


  function showSubTask() {
    if (!projectOption) {
      if (bindObj.cc_trans_bill_to_task_id && tasks.length > 0) {
        let getTaskObj = tasks.find(obj => obj.ts_task_id == bindObj.cc_trans_bill_to_task_id)
        const param = getTaskObj?.ts_task_id;
        if (param !== undefined) {
          dispatch(subtasksActions.getAllById({ param }));
        }

      }
    }
  }


  useEffect(() => {
    showSubTask();
  }, [tasks])


  const radioOptionChange = (e) => {
    const value = e.target.value;
    inputField.credit = value;
    setCreditOption(value)
  }


  const [inputField, setInputField] = useState({
    cc_id: bindObj.cc_id ? bindObj.cc_id : ccIdValue,
    cc_trans_dt: currentDate,
    cc_trans_vendor_id: bindObj.cc_trans_vendor_id ? bindObj.cc_trans_vendor_id : '',
    cc_trans_amt: bindObj.cc_trans_amt ? bindObj.cc_trans_amt?.replace(/-/g, '') : null,
    cc_trans_memo: bindObj.cc_trans_memo ? bindObj.cc_trans_memo : '',
    cc_trans_status: bindObj.cc_trans_status ? bindObj.cc_trans_status : 'S',
    cc_trans_notes: bindObj.cc_trans_notes ? bindObj.cc_trans_notes : null,
    cc_trans_user_id: bindObj.cc_trans_user_id ? bindObj.cc_trans_user_id : userValue,
    cc_qb_account_id: null,
    cc_trans_qb_status: 'P',
    cc_trans_bill_to: bindObj.cc_trans_bill_to ? bindObj.cc_trans_bill_to : '',
    cc_trans_bill_amt: bindObj.cc_trans_amt ? bindObj.cc_trans_amt?.replace(/-/g, '') : null,
    cc_trans_bill_to_client_id: bindObj.cc_trans_bill_to_client_id ? bindObj.cc_trans_bill_to_client_id : '',
    cc_trans_bill_to_user_id: bindObj.cc_trans_bill_to_user_id ? bindObj.cc_trans_bill_to_user_id : '',
    cc_trans_bill_status: 'P',
    client_inv_id: null,
    cc_trans_ticket_number: bindObj.cc_trans_ticket_number ? bindObj.cc_trans_ticket_number : '',
    cc_trans_reviewed: 0,
    cc_trans_bill_to_ts_task_id: bindObj.cc_trans_bill_to_ts_task_id ? bindObj.cc_trans_bill_to_ts_task_id : '',
    credit: parseInt(bindObj.cc_trans_amt, 10) < 0 ? "true" : "false",
    cc_trans_purchase_type: bindObj.cc_trans_purchase_type ? bindObj.cc_trans_purchase_type : '',
    cc_trans_uploaded_by: bindObj.cc_trans_uploaded_by ? bindObj.cc_trans_uploaded_by : userValue,
    cc_trans_bill_to_project_id: bindObj.cc_trans_bill_to_project_id ? bindObj.cc_trans_bill_to_project_id : '',
    cc_trans_id: bindObj.cc_trans_id
  });


  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmit(true)
    if (clientExpTaskLevel == 0 || clientExpTaskLevel == null) {
      inputField.cc_trans_bill_to_ts_task_id = null;
    } else if (clientExpTaskLevel == true) {
      if (subTaskId) {
        inputField.cc_trans_bill_to_ts_task_id = subTaskId;
      } else if (taskOption) {
        inputField.cc_trans_bill_to_ts_task_id = taskOption;
      }
    }
    if (inputField.cc_trans_amt != 0 && inputField.cc_id != '') {
      setLoading(true);
      
      apiCall();
    }
  }


  // function apiCall() {
  //   document.body.style.opacity = 0.5;
  //   const baseUrl = `${process.env.REACT_APP_API_URL}/user/`;
  //   fetch(baseUrl + 'SaveReceipts', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + authUser.jwtToken
  //     },
  //     body: JSON.stringify(inputField)
  //   }).then((response) => {
  //     setLoading(true);
  //     if (response.ok) {
  //       var formData = new FormData();
  //       if (images.length <= 0) {
  //         props.formProps.onHide();
  //         setLoading(false);
  //         document.body.style.opacity = 1;
  //         if (localStorage.getItem('filterObjValues')) {
  //           const reloadData = JSON.parse(localStorage.getItem('filterObjValues'))
  //           dispatch(dashboardActions.getAll(reloadData));
  //         }
  //         return;
  //       }
  //       const fileData = images[0].file;
  //       formData.append("files", fileData);
  //       formData.append("cc_trans_id", bindObj.cc_trans_id);
  //       fetch(baseUrl + 'UploadFile', {
  //         method: 'POST',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Authorization': 'Bearer ' + authUser.jwtToken
  //         },
  //         body: formData
  //       }).then((response) => {
  //         setLoading(true);
  //         if (response.ok) {
  //           window.scrollTo(0, 0);
  //           setResponse(true);
  //           setTimeout(() => {
  //             setResponse(false);
  //             document.body.style.opacity = 1;
  //             props.formProps.onHide();
  //             if (localStorage.getItem('filterObjValues')) {
  //               const reloadData = JSON.parse(localStorage.getItem('filterObjValues'))
  //               dispatch(dashboardActions.getAll(reloadData));
  //             }
  //           }, 2000);
  //           setError('');
  //           setSubmit(false);
  //           setLoading(false);
  //         } else {
  //           setError(response.statusText);
  //           setLoading(false);
  //         }
  //       });
  //     } else {
  //       setError(response.statusText);
  //       setLoading(false);

  //     }
  //   });
  // }
  function apiCall() {
    document.body.style.opacity = 0.5;
    const baseUrl = `${process.env.REACT_APP_API_URL}/user/`;
  
    const formData = new FormData();
  
    for (const key in inputField) {
      if (Object.prototype.hasOwnProperty.call(inputField, key)) {
        formData.append(key, inputField[key]);
      }
    }
    
    if (images.length <= 0) {
     
      props.formProps.onHide();
      setLoading(false);
      document.body.style.opacity = 1;
      if (localStorage.getItem('filterObjValues')) {
        const reloadData = JSON.parse(localStorage.getItem('filterObjValues'))
        dispatch(dashboardActions.getAll(reloadData));
      }
      // return;
    }
  
    const fileData = images && images[0]?.file;

    formData.append("files", fileData);
    formData.append("cc_trans_id", bindObj.cc_trans_id);
  
    fetch(baseUrl + 'UploadFile', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + authUser.jwtToken
      },
      body: formData
    })
    .then((response) => {
      setLoading(true);
      if (response.ok) {
        window.scrollTo(0, 0);
        setResponse(true);
        setTimeout(() => {
          setResponse(false);
          document.body.style.opacity = 1;
          props.formProps.onHide();
          if (localStorage.getItem('filterObjValues')) {
            const reloadData = JSON.parse(localStorage.getItem('filterObjValues'))
            dispatch(dashboardActions.getAll(reloadData));
          }
        }, 2000);
        setError('');
        setSubmit(false);
        setLoading(false);
      } else {
        setError(response.statusText);
        setLoading(false);
      }
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      setError(error.message);
      setLoading(false);
      document.body.style.opacity = 1;
    });
  }
  // function apiCall() {

  //   document.body.style.opacity = 0.5;
  //   const baseUrl = `${process.env.REACT_APP_API_URL}/user/`;
  
  
  //   const formData = new FormData();
  
    
  //   for (const key in inputField) {
  //     if (Object.prototype.hasOwnProperty.call(inputField, key)) {
  //       formData.append(key, inputField[key]);
  //     }
  //   }
  
    
  //   // if (images.length >= 0) {
  //   //   const fileData = images[0].file;
  //   //   formData.append("files", fileData);
  //   // }
  //        if (images.length <= 0) {
  //         // eslint-disable-next-line no-debugger
  //         debugger; 
  //         props.formProps.onHide();
  //         setLoading(false);
  //         document.body.style.opacity = 1;
  //         if (localStorage.getItem('filterObjValues')) {
  //           const reloadData = JSON.parse(localStorage.getItem('filterObjValues'))
  //           dispatch(dashboardActions.getAll(reloadData));
  //         }
  //         return;
  //       }
       
  //       const fileData = images[0].file;
  //       formData.append("files", fileData);
  //       formData.append("cc_trans_id", bindObj.cc_trans_id);
  
    

    
  //   fetch(baseUrl + 'UploadFile', {
  //     method: 'POST',
  //     headers: {
  //       'Authorization': 'Bearer ' + authUser.jwtToken,
  //       'Accept': 'application/json',
  //     },
  //     body: formData,
  //   }).then((response) => {
  //     setLoading(true);
  //     if (response.ok) {
  //       window.scrollTo(0, 0);
  //       setResponse(true);
  //       setTimeout(() => {
  //         setResponse(false);
  //         document.body.style.opacity = 1;
  //         props.formProps.onHide();
  
  //         // Reload the data if required
  //         if (localStorage.getItem('filterObjValues')) {
  //           const reloadData = JSON.parse(localStorage.getItem('filterObjValues'));
  //           dispatch(dashboardActions.getAll(reloadData));
  //         }
  //       }, 2000);
  //       setError('');
  //       setSubmit(false);
  //       setLoading(false);
  //     } else {
  //       setError(response.statusText);
  //       setLoading(false);
  //     }
  //   }).catch((error) => {
  //     setError(error.message);
  //     setLoading(false);
  //   });
  // }
  const getOptionLabel = (option) => {
  
    if (option.cc_active === false) {
      return (
        <div className='gray-bg common-bg'> 
          {option.cc_nbr}
        </div>
      );
    } else  if (option.cc_active === true){
      return (
        <div className='white-bg common-bg'> 
          {option.cc_nbr}
        </div>
      );
    } else {
      return (
        <div className='gray-bg-select'> 
          {option.cc_nbr}
        </div>
      );
    }
  }

  return (
    <>

      <div className="form-overlay">
        <div className="receipts edit-receipts">
          <div className='dash-icon'>
            <div></div>
            <h6 className='dash-title edit-receipt-title'>TEK CC RECEIPT</h6>
            <div></div>
          </div>
          {response &&
            <div className='flex toast-msg'>
              <div className="alert alert-success" role="alert">
                Receipt uploaded successfully
              </div>
            </div>}
          
          {(subtasks.loading || tasks.loading || projects.loading || users.loading || creditcards.loading || payee.loading) &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>}
          {users.length > 0 && payee.length > 0 && creditcards.length &&
            (bindObj.cc_trans_bill_to_project_id ? projects.length > 0 : true) &&
            (bindObj.cc_trans_bill_to_client_id ? clients.length > 0 : true) &&
            (bindObj.cc_trans_bill_to_task_id ? tasks.length > 0 : true) &&
            (bindObj.cc_trans_bill_to_sub_task_id ? subtasks.length > 0 : true) &&
            <main>
              <div className="container-edit container">
                <div className="">
                  <div className="receipts-form">
                    <form onSubmit={handleSubmit}>
                      <div className='dashboard-receipt-edit'>
                        {/* {radio} */}
                        <div className="field-group">
                          <div className="field-element"></div>
                          <div className="field-element flex">
                            <div className="field">
                              <input type="radio" id="charge" value="false" checked={creditOption === "false"} name="credit_option" onChange={radioOptionChange} />
                              <label htmlFor="charge">CHARGE </label> </div>
                            <div className="field credit">
                              <input type="radio" id="credit" name="credit_option" value="true" checked={creditOption === "true"} onChange={radioOptionChange} />
                              <label htmlFor="credit">CREDIT</label></div>
                          </div>
                          <div className="field-element"></div>
                        </div>
                        {/* {radio} */}
                        <div className="field-group">
                          <div className="field-element">
                            <div className="field cc-dropdown">
                              <div className="label">Credit Card<span className="mandatory-field">* </span></div>
                              {creditcards.length > 0 &&
                                <Select options={creditcards}
                                  isClearable={isClearable}
                                  //getOptionLabel={e => e.cc_nbr}
                                  getOptionValue={e => e.cc_id}
                                  defaultValue={bindObj.cc_id ? creditcards.find(obj => obj.cc_id == bindObj.cc_id) : creditDefaultOption}
                                  isSearchable={false}
                                  className="form-custom-class cc-class"
                                  classNamePrefix="form-custom-class-prefix" name="cc_id"
                                  required
                                  getOptionLabel={getOptionLabel}
                                  onChange={data => onChangeGetValue({ target: { value: data?.cc_id, name: 'cc_id' } })}
                                />
                              }
                              {inputField.cc_id == '' && submitCheck &&
                                <span className="error-msg">'Please select a card</span>}
                            </div>
                          </div>

                          <div className="field-element">
                            <div className="field">
                              <div className="label">Used By<span className="mandatory-field">* </span></div>
                              {users.length > 0 &&
                                <Select options={users}
                                  isClearable={isClearable}
                                  getOptionLabel={e => e.user_id}
                                  getOptionValue={e => e.user_id}
                                  defaultValue={bindObj.cc_trans_user_id ? users.find(obj => obj.user_id == bindObj.cc_trans_user_id) : userDefaultValue}
                                  className="form-custom-class"
                                  classNamePrefix="form-custom-class-prefix" name="cc_trans_user_id"
                                  required
                                  onChange={data => onChangeGetValue({ target: { value: data?.user_id, name: 'cc_trans_user_id' } })}
                                />
                              }
                            </div>
                          </div>
                          <div className="field-element">
                            <div className="field">
                              <label htmlFor="cc_trans_dt" className="label">Transaction Date<span className="mandatory-field">* </span></label>
                              <input name="cc_trans_dt" id="date" value={currentDate} type="date" onChange={onChangeGetValue} required max={Todaysdate} />
                            </div>
                          </div>
                        </div>
                        <div className="field-group second-section" key="payee">
                          <div className="field-element">
                            <div className="field">
                              <label htmlFor="cc_trans_vendor_id" className="label">Vendor<span className="mandatory-field">* </span></label>
                              
                              {payee.length > 0 &&
                                <Select options={payee}
                                  isClearable={isClearable}
                                  getOptionLabel={e => e.cc_trans_vendor_name}
                                  getOptionValue={e => e.cc_trans_vendor_id}
                                  defaultValue={payee.find(obj => obj.cc_trans_vendor_id == bindObj.cc_trans_vendor_id)}
                                  className="form-custom-class"
                                  classNamePrefix="form-custom-class-prefix" name="cc_trans_vendor_id"
                                  required
                                  onChange={data => onChangeGetValue({ target: { value: data?.cc_trans_vendor_id, name: 'cc_trans_vendor_id' } })}
                                />
                              }
                            </div>

                          </div>
                          <div className="field-element">
                            <div className="field">
                              <label htmlFor="cc_trans_amt" className="label">Amount<span className="mandatory-field">* </span></label>

                              <CurrencyInput
                                id="input-example"
                                name="cc_trans_amt"
                                decimalsLimit={2}
                                prefix="$ "
                                allowDecimals
                                placeholder="$ 0.00"
                                allowNegativeValue={false}
                                required
                                defaultValue={bindObj.cc_trans_amt?.replace(/,/g, '')?.replace(/-/g, '')}
                                key='amount_key'
                                onValueChange={(value, name) => onChangeGetValue({ target: { value: value, name: 'cc_trans_amt' } })}
                              />
                                                         
        {inputField.cc_trans_amt == 0 &&
                                <span className="error-msg">Please enter &gt; 0 value</span>}
                            </div>
                          </div>
                          <div className="field-element">
                            <label htmlFor="cc_trans_memo" className="label">Description<span className="mandatory-field">* </span></label>
                            <div className="field">
                              <input name="cc_trans_memo" id="memo" type="text" required defaultValue={bindObj.cc_trans_memo} onChange={onChangeGetValue} />
                            </div>
                          </div>

                          <div className="field-element">
                            <label htmlFor="cc_trans_ticket_number" className="label">Ticket Number</label>
                            <div className="field">
                              <input name="cc_trans_ticket_number" id="ticketNumber" defaultValue={bindObj.cc_trans_ticket_number ? bindObj.cc_trans_ticket_number : ticketNumber} type="text" onChange={onChangeticketNumber} />
                            </div>
                          </div>
                        </div>

                        <div className="field-group third-section">
                          <div className="field-element">
                            <div className="field">
                              <label htmlFor="memo" className="label">Bill To<span className="mandatory-field">* </span></label>
                              <Select options={billTo}
                                onChange={(e) => selectBill(e)}
                                isClearable={isClearable}
                                className="form-custom-class"
                                classNamePrefix="form-custom-class-prefix"
                                name="cc_trans_bill_to"
                                defaultValue={billTo.find(obj => obj.value == bindObj.cc_trans_bill_to)}
                                required
                              />
                            </div></div>

                          {billOption == 1 ? (

                            <div className="field-element" key="client">
                              <div className="field">
                                <label htmlFor="cc_trans_bill_to_client_id" className="label">Client<span className="mandatory-field">* </span></label>
                                {clients.length > 0 &&
                                  <Select options={clients}
                                    isClearable={isClearable}
                                    getOptionLabel={e => e.client_name}
                                    getOptionValue={e => e.client_id}
                                    onChange={(e) => selectClient(e)}
                                    className="form-custom-class"
                                    classNamePrefix="form-custom-class-prefix"
                                    name="cc_trans_bill_to_client_id"
                                    defaultValue={clients.find(obj => obj.client_id == bindObj.cc_trans_bill_to_client_id)}
                                    required
                                  />
                                }
                              </div>
                            </div>) :
                            billOption == 2 ? (
                              <div className="field-element" key="department">
                                <div className="field">
                                  <label htmlFor="memo" className="label">Purchase Type</label>
                                  {purchaseType.length > 0 &&
                                    <Select options={purchaseType}
                                      isClearable={isClearable}
                                      getOptionLabel={e => e.cc_trans_purchase_type}
                                      getOptionValue={e => e.cc_trans_purchase_type_id}
                                      onChange={(e) => selectPurchaseType(e)}
                                      defaultValue={purchaseType.find(obj => obj.cc_trans_purchase_type_id == bindObj.cc_trans_purchase_type_id)}
                                      className="form-custom-class"
                                      classNamePrefix="form-custom-class-prefix"
                                      name="cc_trans_purchase_type_id"
                                    />}
                                </div>
                              </div>) :
                              billOption == 3 ?
                                (
                                  <div className="field-element" key="personal">
                                    <div className="field">
                                      <label htmlFor="cc_trans_bill_to_user_id" className="label">Name<span className="mandatory-field">* </span></label>
                                      {users.length > 0 &&
                                        <Select options={users}
                                          isClearable={isClearable}
                                          getOptionLabel={e => e.user_id}
                                          getOptionValue={e => e.user_id}
                                          onChange={(e) => selectPersonal(e)}
                                          defaultValue={users.find(obj => obj.user_id == bindObj.cc_trans_bill_to_user_id)}
                                          className="form-custom-class"
                                          classNamePrefix="form-custom-class-prefix"
                                          name="cc_trans_bill_to_user_id"
                                          required
                                        />}
                                    </div>
                                  </div>) : (<div>

                                  </div>)}
                          {/* client options*/}
                          {((clientClear && (clientExpTaskLevel != null && clientExpTaskLevel != 0) && bindObj.cc_trans_bill_to_project_id && bindObj.cc_trans_bill_to == 'C') || (projects.length > 0 && clientOption && (clientExpTaskLevel != null && clientExpTaskLevel != 0))) && (billOption != 2 && billOption != 3) ? (
                            <div className="field-element" key="Projects">
                              <div className="field">
                                <label htmlFor="memo" className="label">Project<span className="mandatory-field">* </span></label>
                                {projects.length > 0 &&
                                  <Select options={projects}
                                    isClearable={isClearable}
                                    getOptionLabel={e => e.project_name}
                                    getOptionValue={e => e.project_id}
                                    onChange={(e) => selectProject(e)}
                                    defaultValue={projects.find(obj => obj.project_id == bindObj.cc_trans_bill_to_project_id)}
                                    className="form-custom-class"
                                    classNamePrefix="form-custom-class-prefix"
                                    name="cc_trans_bill_to_project_id"
                                    required
                                  />}
                              </div>
                            </div>) : (<div className="field-element"></div>)}
                          {/* client options*/}
                        </div>
                        {/* {new dropdown} */}

                        <div className="field-group">
                          {(((clientClear && (clientExpTaskLevel != null && clientExpTaskLevel != 0) && projectClear && bindObj.cc_trans_bill_to_task_id && bindObj.cc_trans_bill_to == 'C') || (tasks.length > 0 && projectOption && clientOption && projects.length > 0) && (clientExpTaskLevel != null && clientExpTaskLevel != 0)) && (billOption != 2 && billOption != 3)) ? (
                            <div className="field-element">
                              <div className="field">
                                <label htmlFor="memo" className="label">Task<span className="mandatory-field">* </span></label>
                                {tasks.length > 0 &&
                                  <Select options={tasks}
                                    onChange={(e) => selectTasks(e)}
                                    isClearable={isClearable}
                                    getOptionLabel={e => e.task_name}
                                    getOptionValue={e => e.ts_task_id}
                                    defaultValue={tasks.find(obj => obj.ts_task_id == bindObj.cc_trans_bill_to_task_id)}
                                    className="form-custom-class"
                                    classNamePrefix="form-custom-class-prefix"
                                    name="bill_to"
                                    required
                                  />}
                              </div></div>) : <div></div>}

                          {(((clientClear && (clientExpTaskLevel != null && clientExpTaskLevel != 0) && projectClear && taskClear && bindObj.cc_trans_bill_to_sub_task_id && bindObj.cc_trans_bill_to == 'C') || (tasks.length > 0 && subtasks.length > 0 && clientOption && taskOption && projectOption && projects.length > 0) && (clientExpTaskLevel != null && clientExpTaskLevel != 0)) && (billOption != 2 && billOption != 3)) ? (
                            <div className="field-element">
                              <div className="field">
                                {subtasks.length > 0 &&
                                  <label htmlFor="memo" className="label">Sub Task<span className="mandatory-field">* </span></label>}
                                {subtasks.length > 0 &&
                                  <Select options={subtasks}
                                    onChange={(e) => selectSubTasks(e)}
                                    isClearable={isClearable}
                                    getOptionLabel={e => e.task_name}
                                    getOptionValue={e => e.ts_task_id}
                                    defaultValue={subtasks.find(obj => obj.ts_task_id == bindObj.cc_trans_bill_to_sub_task_id)}
                                    className="form-custom-class"
                                    classNamePrefix="form-custom-class-prefix"
                                    name="bill_to"
                                    required
                                  />}
                              </div></div>) : (
                            <div className="field-element"></div>)}
                          <div className="field-element"></div>
                        </div>
                        {/* {tasks end} */}

                        {/* <div>conditional</div> */}
                        {/* {upload}  */}
                        <div className='button-message'>
                          <div className="upload-images-div">
                            <div className="desk-view-r">
                              <div className="field-group">
                                <div className="field">
                                  <div className="button-wrap" id='image-upload'>
                                    <ImageUploading
                                      value={images}
                                      onChange={onChange}
                                      maxNumber={maxNumber}
                                      dataURLKey="data_url"
                                      acceptType={['jpg', 'jpeg', 'pdf', 'png', 'html']}
                                      allowNonImageType={true}
                                    >
                                      {({
                                        imageList,
                                        onImageUpload,
                                        isDragging,
                                        dragProps,
                                      }) => (

                                        <div className="upload__image-wrapper">
                                          <div className='upload-button'
                                            style={isDragging ? { color: 'red' } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                          >
                                            <span className='mobile-view'><img src={'/assets/images/camera-small.png'} /> *</span>
                                            <span className='desk-view'>ATTACH TEK CC RECEIPT *</span>
                                          </div>
                                          {images.length == 0 &&
                                            <div className="uploaded-file">
                                              <a href={bindObj.file_path} target="_blank"><img src={'/assets/images/PDF_file_icon.png?' + new Date().getTime()} /></a>
                                            </div>
                                          }
                                          &nbsp;
                                          {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                              {image['file']?.type == "application/pdf" ? (
                                                <div><img src={'assets/images/PDF_file_icon.png'} className="App-logo" alt="logo" />
                                                </div>) : image['file']?.type == "text/html" ?
                                                (<div><img src={'assets/images/html_icon.png'} className="App-logo" alt="logo" />
                                                </div>) : (
                                                  <div>
                                                    <img src={image['data_url']} alt="" width="100" />

                                                  </div>)}
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </ImageUploading>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="field-group">
                              <div className="field">
                                <i className='file-format'>Image, PDF &amp; HTML files allowed</i>
                                <div className='receive-save-wrapper'>
                                  <div className="sign-btn flex">
                                    <button type="submit" className="btn btn-primary receipt-save" disabled={loading}>
                                      Submit</button>
                                    <div className="btn btn-danger receipt-cancel" onClick={props.formProps.onHide}>
                                      Cancel</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="field-group">
                              <div className="field">
                                <div className='receive-save-wrapper'>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="field-group">
                            <div className="error-msg alert alert-success mt-3 mb-0">{saveError}</div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>}
            
        </div>
      </div>

    </>
  );
}


